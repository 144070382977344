export const appHead = {"meta":[{"charset":"utf-8"},{"name":"charset","content":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"name":"author","content":"Victor Ahuwanya"}],"link":[{"rel":"icon","href":"/assets/images/icon.webp","type":"image/webp","defer":true},{"rel":"preload","defer":true,"as":"style","onload":"this.onload=null;this.rel='stylesheet'","href":"/app.css"},{"rel":"preload","defer":true,"as":"style","onload":"this.onload=null;this.rel='stylesheet'","href":"/assets/css/sweetalert2.min.css"},{"rel":"preload","defer":true,"as":"style","onload":"this.onload=null;this.rel='stylesheet'","href":"/assets/css/main.css"}],"style":[],"script":[{"data-domain":"forumleadsfinder.virock.org","src":"https://pdqserver.virock.org/js/script.js","defer":true},{"src":"https://cdnjs.cloudflare.com/ajax/libs/sweetalert2/11.10.8/sweetalert2.min.js","defer":true},{"src":"/assets/js/jquery-3.3.1.min.js","defer":true},{"src":"/assets/js/jquery-1.9.1.min.js","defer":true},{"src":"/assets/js/easyResponsiveTabs.js","defer":true},{"src":"/assets/js/owl.carousel.js","defer":true},{"src":"/assets/js/jquery.magnific-popup.min.js","defer":true},{"src":"/assets/js/bootstrap.min.js","defer":true},{"src":"/js/izitoast.js","defer":true},{"src":"https://unpkg.com/boxicons@2.1.4/dist/boxicons.js","defer":true}],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000
export default class Globals {
    static VERSION: string = "VERSION";
    static FILES: string = "FILES";
    static ONE_YEAR: number = 60 * 60 * 24 * 365;
    static CLIENT_DEVICES: string = "CLIENT_DEVICES";
    static FILES_TO_BE_DELETED: string = "FILES_TO_BE_DELETED";
    static USER_ID_STORAGE_KEY: string = "user_id";
    static USER_LOG_HEADER_NAME: string = "user-log-id";
    static DEV_EMAIL: string = "victorskywatch@gmail.com";
    static Identity: string = "identity";
    static COOKIE: string = "cookie";
    static NormalizedNgram: string = "NormalizedNgram";
}
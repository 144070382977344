<template>
    <section class="w3l-footer-29-main">
        <div class="footer-29 py-5">
            <div class="container py-lg-4">
                <div class="row footer-top-29">
                    <div class="col-md-4 footer-list-29 footer-1 pe-lg-5">
                        <div class="footer-logo mb-4">
                            <h2>
                                <a class="navbar-brand" href="/">
                                    <span class="sublog">ForumLeads</span>Finder
                                </a>
                            </h2>
                        </div>
                        <p>
                            Your one stop client acquisition solution. Check out the 7 days free trial. No credit card
                            needed.
                        </p>
                        <div class="w3l-two-buttons mt-4">
                            <NuxtLink to="/signup" class="btn btn-primary btn-style"> Try it
                                For Free </NuxtLink>
                        </div>
                        <div class="main-social-footer-29 mt-5">
                            <a target="_blank" href="https://web.facebook.com/profile.php?id=100094206535981"
                                class="facebook">
                                <span class="fab fa-facebook-f"></span>
                            </a>
                            <a target="_blank" href="https://x.com/Virockorg" class="twitter">
                                <span class="fab fa-twitter"></span>
                            </a>
                            <a target="_blank" href="https://www.instagram.com/virock.software/" class="instagram">
                                <span class="fab fa-instagram"></span>
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/in/victor-ahuwanya/" class="linkedin">
                                <span class="fab fa-linkedin-in"></span>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 footer-list-29 footer-2 mt-sm-0 mt-5">
                        <ul>
                            <h6 class="footer-title-29 text-center">Other Products By Virock</h6>
                        </ul>
                        <div class="row">
                            <div class="col-md-6 separation" v-for="(work, indexaa) in data.projects" :key="indexaa">
                                <ul>
                                    <li><a target="_blank" :href="work.link" :title="work.description">{{ work.name
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 footer-list-29 footer-4 mt-lg-0 mt-5">
                        <h6 class="footer-title-29">Contact</h6>
                        <ul>
                            <li>
                                <a href="mailto:support@virock.org">support@virock.org</a>
                            </li>
                          <li>
                            <NuxtLink to="/partners">Partners</NuxtLink>
                          </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <section class="w3l-copyright text-center">
            <div class="container">
                <p class="copy-footer-29"><a href="https://www.flaticon.com/free-icons/lead" title="lead icons"
                        target="_blank">Lead icons created by Flat Icons - Flaticon</a></p>
                <p class="copy-footer-29">© {{ new MyDate().toString("YYYY") }} Virock. All rights reserved.</p>
            </div>
            <button onclick="topFunction()" id="movetop" title="Go to top">
                <span class="fas fa-arrow-up"></span>
            </button>
        </section>
    </section>
</template>


<script setup lang="ts">
import type { NuxtApp } from "#app";
import Log from "../../Common/Client/Log";
import Info from "../../Common/Client/Info";
import MyDate from "../../Common/Common/MyDate";
import Globals from "../global";

const headers = useRequestHeaders(['cookie'])
const route = useRoute();
const projects = ref([]);

function log(message: string, isInfo: boolean = true) {
    Log.log(message, route.path, isInfo, headers);
}

const {
    data,
} = await useAsyncData(async function (ctx?: NuxtApp | undefined): Promise<unknown> {
    try {
        const projects = await $fetch(`/api/projects`, {
          method: "GET",
        });
        return { projects: projects };
    } catch (ex) {
        log(`Error occurred while trying get all projects by Virock`, false);
        log(Info.prettifyIfPossible(ex), false);
        Log.reportError();
    }
});

onMounted(() => {
    // log(`projects: ${Info.prettifyIfPossible(data.value.projects)}`);
});


</script>

<style scoped>
.separation {
    margin-top: 5px;
    margin-bottom: 5px;
}
</style>
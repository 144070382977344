// import {inspect} from "util";



export default class Info {

    public static prettifyIfPossible(value: any): any {
        // if (!value)
        //     return null;
        // let result: string | boolean = JSON.stringify(value, null, 1);
        // if (Object.keys(result).length === 0 && result.constructor === Object)
        //     result = value;
        // return result;

        if (!value)
            return null;
        const allKeys: string[] = Object.getOwnPropertyNames(value)
        const data: any = {};
        allKeys.forEach((key: string) => {
            data[key] = value[key];
        });
        return JSON.stringify(data, null, 1);

        // return inspect(value, {colors: true, showHidden: true, compact: false, depth: Infinity, getters: true, showProxy: true, breakLength: Infinity, maxArrayLength: Infinity, maxStringLength: Infinity});
    }

    public static isDebugMode() : boolean
    {
        //@ts-ignore
        return process.dev;
    }

    public static isEmailValid(email: string): boolean {
        const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email.match(validEmailRegex))
            return false;
        return true;
    }

    public static isWebsiteLinkValid(websiteLink: string): boolean {
        let url;

        try {
            url = new URL(websiteLink);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    public static isDigitsOnly(value: string, spaceAllowed: boolean = false, hyphenAllowed: boolean = false): boolean {
        let digits: string = "0123456789";
        if (spaceAllowed)
            digits += " ";
        if (hyphenAllowed)
            digits += "-";
        for (let i = 0; i < value.length; i++)
            if (!digits.includes(value[i]))
                return false;
        return true;
    }

    public static isValidDate(date: string) {
        const newDate = new Date(date);
        return newDate instanceof Date && !Number.isNaN(newDate);
    }
}



import Info from "../Common/Client/Info";
import CommonInfo from "../Common/Common/Info";
import MyDate from "../Common/Common/MyDate";

export default class Global {

    public static REDDIT_CLIENT_ID: string = "hlJQH_3LWnqAyin8dBOzHg";
    public static REDDIT_REDIRECT_URL: string = "https://forumleadsfinder.virock.org/redirect";
    public static ONE_YEAR: number = 60 * 60 * 24 * 365;
    public static NameOfApp: string;
    public static getHomepage(): string {
        if (CommonInfo.isDebugMode())
            return "http://localhost:3000"
        return "https://forumleadsfinder.virock.org";
    }
}

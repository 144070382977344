import moment from "moment";
import {Moment} from "moment";

export default class MyDate {
    private date: Moment;

    /**
     *
     * @param value - If number type, pass milliseconds
     */
    constructor(value: string | number | Date = Date.now()) {
        this.date = moment(value)
    }

    public getMoment(): Moment{
        return this.date;
    }

    public addDays(numberOfDays: number): MyDate {
        const newDate = moment(this.date.toDate());
        newDate.add(numberOfDays, 'days');
        return new MyDate(newDate.toDate());
    }

    public addSeconds(numberOfSeconds: number): MyDate {
        const newDate = moment(this.date.toDate());
        newDate.add(numberOfSeconds, 'seconds');
        return new MyDate(newDate.toDate());
    }

    public addMinutes(numberOfMinutes: number): MyDate {
        const newDate = moment(this.date.toDate());
        newDate.add(numberOfMinutes, 'minutes');
        return new MyDate(newDate.toDate());
    }

    public addHours(numberOfHours: number): MyDate {
        const newDate = moment(this.date.toDate());
        newDate.add(numberOfHours, 'hours');
        return new MyDate(newDate.toDate());
    }

    public compareTo(value: string | number | Date | MyDate): number {
        let otherDate: MyDate;
        if (value instanceof MyDate)
            otherDate = value;
        else
            otherDate = new MyDate(value);
        if (this.date.isBefore(otherDate.getMoment()))
            return -1;
        else if (this.date.isSame(otherDate.getMoment()))
            return 0;
        return 1;
    }

    public toString(format: string): string {
        return this.date.format(format);
    }

    public toDateString() {
        return this.date.format("YYYY-MM-DD");
    }

    public toLongDateTimeString() {
        return this.date.format("MMMM Do YYYY, h:mm:ss a");
    }

}



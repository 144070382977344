<template>
    <header id="site-header" class="fixed-top">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light stroke py-lg-0">
                <h1>
                    <NuxtLink v-if="data.loggedIn" class="navbar-brand pe-xl-5 pe-lg-4" to="/dashboard"><span class="sublog">ForumLeads</span>Finder</NuxtLink>
                    <a v-else class="navbar-brand pe-xl-5 pe-lg-4" href="/"><span class="sublog">ForumLeads</span>Finder</a>
                </h1>
                <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false"
                    aria-label="Toggle navigation" id="menuTogglerButton">
                    <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
                    <span class="navbar-toggler-icon fa icon-close fa-times"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarScroll">
                    <ul class="navbar-nav me-lg-auto my-2 my-lg-0 navbar-nav-scroll">
                        <li v-if="!data.loggedIn" class="nav-item" v-on:click="toggleMenu">
                            <a class="nav-link" aria-current="page" href="/">Home</a>
                        </li>
                        <li v-else class="nav-item" v-on:click="toggleMenu">
                            <NuxtLink class="nav-link" aria-current="page" to="/dashboard">Home</NuxtLink>
                        </li>
                        <li class="nav-item" v-on:click="toggleMenu" v-if="!data.loggedIn">
                            <NuxtLink class="nav-link" aria-current="page" to="/login">Login
                            </NuxtLink>
                        </li>
                        <li class="nav-item" v-on:click="toggleMenu" v-if="!data.loggedIn">
                            <NuxtLink class="nav-link" aria-current="page" to="/signup">Sign
                                up</NuxtLink>
                        </li>
                      <li class="nav-item" v-on:click="toggleMenu">
                        <NuxtLink class="nav-link" aria-current="page" to="/blog">Blog
                        </NuxtLink>
                      </li>
                        <li class="nav-item" v-on:click="toggleMenu">
                            <NuxtLink class="nav-link" aria-current="page" to="/about">About
                            </NuxtLink>
                        </li>
                        <li class="nav-item" v-on:click="toggleMenu">
                            <a class="nav-link" href="mailto:support@virock.org">Contact</a>
                        </li>
                        <li class="nav-item" v-on:click="toggleMenu" v-if="data.loggedIn">
                            <NuxtLink class="nav-link" aria-current="page" to="/logout">Logout
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
                <div class="mobile-position">
                    <nav class="navigation">
                        <div class="theme-switch-wrapper">
                            <label class="theme-switch clickable" for="checkbox">
                                <input type="checkbox" id="checkbox">
                                <div class="mode-container">
                                    <i class="gg-sun"></i>
                                    <i class="gg-moon"></i>
                                </div>
                            </label>
                        </div>
                    </nav>
                </div>
            </nav>
        </div>
    </header>
</template>

<script setup lang="ts">
import type { NuxtApp } from "#app";
import Globals from "../global";

const headers = useRequestHeaders(['cookie'])
const token = useCookie("TOKEN", { maxAge: Globals.ONE_YEAR, path: "/", sameSite: "lax" });
const route = useRoute();

function toggleMenu() {
    if (window.innerWidth > 991)
        return;
    //@ts-ignore
    menuTogglerButton.click();
}

const {
    data, refresh
} = await useAsyncData(async function (ctx?: NuxtApp | undefined): Promise<unknown> {
    return { loggedIn: token.value != null };
});

watch(token, () => {
  refresh();
}, {immediate: true});

</script>

<style scoped></style>
<script setup lang="ts">
import Log from "../Common/Client/Log";
import { onMounted } from "@vue/runtime-core";
// import Footer from "./components/footer.vue";
// import Header from "./components/header.vue";

function addScript() {
  const scriptElement = document.createElement("script");
  scriptElement.src = "/assets/js/main.js"
  const bodyElement = document.getElementsByTagName("body")[0];
  bodyElement.appendChild(scriptElement);
}

onMounted(()=> {
	setTimeout(addScript, 100);
});

onMounted(() => {
  Log.setVersion("0");
  Log.sendLogsEvery10Seconds();
});

</script>

<template>
  <div>
    <Header />
    <NuxtPage />
    <Footer />
  </div>
</template>

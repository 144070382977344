import axios, {AxiosError} from "axios";
import Info from "./Info";
import Globals from "../Common/Globals";
import Cookies from "js-cookie";

const LOGS_STORAGE_KEY: string = "logs";

export default class Log {

    private static apiHomepage?: string;

    public static setVersion(version: string) {
        localStorage.setItem(Globals.VERSION, version);
    }

    private static getVersion(): string {
        //Get user's unique id
        let version = localStorage.getItem(Globals.VERSION);
        //If id does not exist, create one and store in local storage
        if (!version) {
            version = "0";
            localStorage.setItem(Globals.VERSION, version);
        }
        return version;
    }

    private static makeSureUserExists(): string {
        //Get user's unique id
        let user_id = Cookies.get(Globals.USER_ID_STORAGE_KEY);
        //If id does not exist, create one and store in local storage
        if (!user_id) {
            user_id = new Date().getTime() + "" + Math.floor(Math.random() * 1000000 + 1);
            Cookies.set(Globals.USER_ID_STORAGE_KEY, user_id, {expires: 365});
        }
        return user_id;
    }

    private static sendLogs(logger_endpoint: string): void {
        Log.makeSureUserExists();
        // @ts-ignore
        const queue_of_logs_to_write: object[] = JSON.parse(localStorage.getItem(LOGS_STORAGE_KEY));
        localStorage.removeItem(LOGS_STORAGE_KEY);
        if (queue_of_logs_to_write == null || queue_of_logs_to_write.length == 0)
            return;

        axios.post(logger_endpoint, {
            logs: queue_of_logs_to_write
        })
            .catch(function (err: AxiosError) {
            });
    }

    static sendLogsEvery10Seconds(logger_endpoint?: string, apiHomepage?: string): void {
        if (apiHomepage)
            this.apiHomepage = apiHomepage;
        Log.makeSureUserExists();
        if (!logger_endpoint)
            logger_endpoint = `/api/logs/client`;
        setInterval(this.sendLogs, 10000, logger_endpoint);
    }

    public static reportError(headers: { cookie?: string | undefined } = undefined) {
        Log.makeSureUserExists();
        if (!headers) {
            const version: string = this.getVersion();
            axios.post(`/api/logs/error`, {
                version: version
            })
                .catch(function (err: AxiosError) {
                });
        } else {
            //@ts-ignore
            $fetch(`${apiHomepage}/api/logs/error`, {method: "POST", headers});
        }
    }

    public static reportErrorV2() {
        const version: string = this.getVersion();
        Log.makeSureUserExists();
        axios.post(`/api/logs/error`, {
            version: version
        })
            .catch(function (err: AxiosError) {
            });
    }

    private static logThis(message: string | number, path: string, is_info: boolean = true): void {
        const browser_details = Info.getBrowserDetails();
        if (browser_details.UserAgent.includes("Googlebot")) return;
        //Append this log to the local storage
        //@ts-ignore
        message = `${message} Path: ${path} Client Side`;
        //Get localstorage log contents
        let logs: string | null = localStorage.getItem(LOGS_STORAGE_KEY);
        //If empty, create log
        if (logs == null || logs.length == 0)
            logs = JSON.stringify([{is_info, message}]);
        //Append this message to it
        else {
            const array_of_logs: object[] = JSON.parse(logs);
            array_of_logs.push({is_info, message});
            logs = JSON.stringify(array_of_logs);
        }
        localStorage.setItem(LOGS_STORAGE_KEY, logs);
    }

    static log(message: string | number, path: string, is_info: boolean = true, headers?: any, v2: boolean = false): void {
        //Check if this is server
        //@ts-ignore
        const server: boolean = process.server;
        if (server) {
            if (v2) {
                console.log(message);
                return;
            }
            //@ts-ignore
            $fetch(`/api/logs/server`, {method: "POST", body: {message, path, is_info}, headers});
            return;
        }
        //@ts-ignore
        if (process.dev)
            console.log(message);
        //client, push to local storage
        Log.logThis(message, path, is_info);
    }
}
import { default as about0RIb4PglxqMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/about.vue?macro=true";
import { default as add_45sequenceCdfsvMMlOZMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/add-sequence.vue?macro=true";
import { default as forum_45marketingWxTunFH1JXMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/blog/forum-marketing.vue?macro=true";
import { default as indexr7hNbtJvf8Meta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/blog/index.vue?macro=true";
import { default as sales_45leadsIbYlT4lOEcMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/blog/sales-leads.vue?macro=true";
import { default as social_45media_45marketingUqH4ro90qEMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/blog/social-media-marketing.vue?macro=true";
import { default as dashboardf2xSv5kqGiMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/dashboard.vue?macro=true";
import { default as indexC5B8PDj9eDMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/index.vue?macro=true";
import { default as invalid_45verification_45codefhXRnrIyZGMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/invalid-verification-code.vue?macro=true";
import { default as loginD5MIcGKhm4Meta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/login.vue?macro=true";
import { default as logoutByAfWHJAuoMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/logout.vue?macro=true";
import { default as partnersfIErRmfV9NMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/partners.vue?macro=true";
import { default as payment_45confirmationbL0U3WNY22Meta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/payment-confirmation.vue?macro=true";
import { default as purchase_45tokensTuVIoCZ5XwMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/purchase-tokens.vue?macro=true";
import { default as redirectLEIDcMnC3cMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/redirect.vue?macro=true";
import { default as _91key_937AX3ZRMoiDMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/sequence/[key].vue?macro=true";
import { default as signupaXtt4LiET6Meta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/signup.vue?macro=true";
import { default as valid_45verification_45codesSebB76pXNMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/valid-verification-code.vue?macro=true";
import { default as _91code_935E5DFmqwhlMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/verify-email/[code].vue?macro=true";
import { default as indexeEDvV2vp9eMeta } from "/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/verify-email/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "add-sequence",
    path: "/add-sequence",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/add-sequence.vue").then(m => m.default || m)
  },
  {
    name: "blog-forum-marketing",
    path: "/blog/forum-marketing",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/blog/forum-marketing.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-sales-leads",
    path: "/blog/sales-leads",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/blog/sales-leads.vue").then(m => m.default || m)
  },
  {
    name: "blog-social-media-marketing",
    path: "/blog/social-media-marketing",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/blog/social-media-marketing.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invalid-verification-code",
    path: "/invalid-verification-code",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/invalid-verification-code.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: "payment-confirmation",
    path: "/payment-confirmation",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/payment-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "purchase-tokens",
    path: "/purchase-tokens",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/purchase-tokens.vue").then(m => m.default || m)
  },
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: "sequence-key",
    path: "/sequence/:key()",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/sequence/[key].vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "valid-verification-code",
    path: "/valid-verification-code",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/valid-verification-code.vue").then(m => m.default || m)
  },
  {
    name: "verify-email-code",
    path: "/verify-email/:code()",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/verify-email/[code].vue").then(m => m.default || m)
  },
  {
    name: "verify-email",
    path: "/verify-email",
    component: () => import("/home/Nuxt3/ForumLeadsFinder-Nuxt3/pages/verify-email/index.vue").then(m => m.default || m)
  }
]